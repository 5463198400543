<template>
  <div class="form-container">
    <p class="heading">Register to create an account</p>
    <form class="mt-4" @submit.prevent="registerUser">
      <div class="form-group">
        <input type="text" class="form-input-box" id="exampleInputEmail1" placeholder=" Full Name" v-model="full_name">
      </div>
      <div class="form-group">
        <input type="email" class="form-input-box" id="exampleInputEmail2" placeholder=" Email Address" v-model="email">
      </div>
      <div class="form-group">
        <input type="password" class="form-input-box" id="exampleInputPassword1" placeholder=" Password" v-model="password">
      </div>
      <div class="form-group">
        <input type="password" class="form-input-box" id="exampleInputPassword1" placeholder=" Confirm Password" v-model="confirmPassword">
      </div>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block pt-1">
          <input type="checkbox" class="checkbox custom-control-input" id="customCheck1" v-model="tnc_flag">
          <label class="form-text custom-control-label" for="customCheck1">I accept the <a href="#">Terms and Conditions</a></label>
        </div>
        <button type="submit" class="btn-confirm">Confirm</button>
      </div>
      <div class="signin_info">
        <hr>
        <span class="form-text dark-color d-inline-block line-height-2">Already Have Account ? <router-link :to="{ name: 'auth1.sign-in1'}">Log In</router-link></span>
      </div>
    </form>
    <div v-if="getErrorStatus" class="bread_crumb">
      <b-alert v-model="changeErrorStatus" variant=" " dismissible fade class="text-white bg-danger">
        <div class="iq-alert-icon">
          <i class="ri-information-line"></i>
        </div>
        <div class="iq-alert-text">{{getErrorMessage}}</div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'SignUp1',
  components: {
  },
  computed: {
    ...mapGetters({
      getErrorStatus: 'getErrorStatus',
      getErrorMessage: 'getErrorMessage'
    }),
    changeErrorStatus: {
      get () {
        return this.getErrorStatus
      },
      set () {
        // console.log('INSIDE SETTER')
        this.setErrorStatus(false)
      }

    }
  },
  data () {
    return {
      full_name: '',
      email: '',
      password: '',
      confirmPassword: '',
      tnc_flag: false
    }
  },
  methods: {
    ...mapActions([
      'REGISTER'
    ]),
    ...mapMutations([
      'setErrorStatus',
      'setErrorMessage'
    ]),
    registerUser () {
      const response = {
        name: this.full_name,
        emailId: this.email,
        password: this.password
      }
      if (this.password !== this.confirmPassword) {
        this.setErrorStatus(true)
        this.setErrorMessage('Password does not match!!')
      } else if (!this.tnc_flag) {
        this.setErrorStatus(true)
        this.setErrorMessage('Please accept Terms and Conditions')
      } else {
        this.REGISTER({
          payload: response,
          success: () => {
            this.$router.push({ name: 'auth1.confirm-mail1', params: { emailID: this.email } })
          }
        })
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.heading {
  font-weight: 200 !important;
  font-size: 1.8vw;
  transform: scale(1.1, 1);
  color: white !important;
  text-align: center;
}
.btn-confirm {
  background-color: #87def8;
  float: right;
  width: 8vw;
  border-radius: 7px;
  border-width: 0px;
  font-weight: 510;
  font-size: 1.1vw;
}
.form {
  &-container{
  width: 40%;
  margin: 0 auto;
  }
  &-input-box{
    background-color: whitesmoke;
    height: 4vh;
    width: 100%;
    border-radius: 6px;
    font-size: 1.3vw;
    border-width: 0px;
  }
  &-text {
    color: white !important;
    text-align: center;
    font-size: 1vw;
  }
}
::placeholder {
  font-size: 1.3vw;
  opacity: 0.7;
}
.signin_info {
  text-align: center;
}
hr {
  margin-bottom: 0.5rem;
}
.bread_crumb {
  margin-top: -7vh;
}
</style>
